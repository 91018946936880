import React from "react";

import './logo.styles.css'

const Logo = () => {
  return (
    <div className="logo-container">
        <h1 className="logo-text">VK</h1>
    </div>
  );
};

export default Logo;
